import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

const Accordion = withStyles({
  root: {},
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      margin: 0,
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

export interface AccordionPanelsProps extends React.HTMLAttributes<HTMLElement> {
  panels: {
    details: JSX.Element | string
    summary: JSX.Element | string
  }[]
}

export const AccordionPanels: React.FC<AccordionPanelsProps> = (props) => {
  const { id = ``, panels } = props
  const [expanded, setExpanded] = React.useState(0)

  return (
    <React.Fragment>
      {panels.map(({ details, summary }, index) => {
        const handleChange = (_event: unknown, newExpanded: boolean) => {
          if (newExpanded) {
            setExpanded(index)
          }
        }
        const panel = [id, `panel`, index].filter((x) => x !== ``).join("-")
        return (
          <Accordion
            elevation={0}
            expanded={expanded === index}
            key={index}
            onChange={handleChange}
            square={true}
          >
            <AccordionSummary
              aria-controls={`${panel}-content`}
              expandIcon={<ExpandMoreIcon />}
              id={`${panel}-header`}
            >
              {summary}
            </AccordionSummary>
            <AccordionDetails>{details}</AccordionDetails>
          </Accordion>
        )
      })}
    </React.Fragment>
  )
}
