import {
  Box,
  ButtonBase,
  Dialog,
  DialogContent,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import CloseIcon from "@material-ui/icons/Close"
import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import React, { useRef } from "react"
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom-ssr"
import SwipeableViews, { OnChangeIndexCallback } from "react-swipeable-views"

import theme from "../gatsby-theme-material-ui-top-layout/theme"
import { Image } from "../interfaces/image"
import { getImgSrc } from "../utils/images"
import { LazySizesImg } from "./LazySizesImg"

interface StylesProps {
  imageWidth: number
  imageHeight: number
  thumbWidth: number
  thumbHeight: number
}

const useStyles = makeStyles<Theme, StylesProps>({
  thumb: {
    height: ({ thumbHeight }) => thumbHeight,
    width: ({ thumbWidth }) => thumbWidth,
    border: "1px solid lightgray", // HACK: Match the size of selected dots.
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
  currentThumb: {
    border: "1px solid black",
  },
  image: {
    margin: "auto",
    height: 200,
    width: 200,
    [theme.breakpoints.up("md")]: {
      height: 500,
      width: 500,
    },
  },
  button: {
    position: "absolute",
    top: "50%",
  },
  prevButton: {
    left: 0,
  },
  nextButton: {
    right: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogContent: {
    background: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  fullScreen: {
    height: "100%",
    objectFit: "contain",
  },
  slide: {
    display: "flex",
  },
})

interface ImageSliderProps {
  images: Image[]
  imageWidth?: number
  imageHeight?: number
  thumbWidth?: number
  thumbHeight?: number
  zoomWidth?: number
  zoomHeight?: number
}

export const ImageSlider: React.FC<ImageSliderProps> = (props) => {
  const {
    images,
    imageWidth = 200,
    imageHeight = 200,
    thumbWidth = 100,
    thumbHeight = 100,
    zoomWidth = 2000,
    zoomHeight = 2000,
  } = props
  const classes = useStyles({ ...props, imageHeight, imageWidth, thumbHeight, thumbWidth })

  const data = useStaticQuery(query)
  const { site } = data
  const { siteMetadata } = site
  const { imageBucket } = siteMetadata

  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const isFirstSlide = currentIndex === 0
  const isLastSlide = currentIndex === images.length - 1
  const currentImage = images[currentIndex]

  const handleChangeIndex: OnChangeIndexCallback = (index) => {
    setCurrentIndex(index)
  }

  const handlePrevClick = () => {
    setCurrentIndex(currentIndex - 1)
  }

  const handleNextClick = () => {
    setCurrentIndex(currentIndex + 1)
  }

  const handleCloseDialog = (): void => {
    setDialogOpen(false)
  }

  const handleOpenDialog = (): void => {
    setDialogOpen(true)
  }

  const zoomSrc = getImgSrc(imageBucket, currentImage.src, {
    resize: {
      width: zoomWidth,
      height: zoomHeight,
      fit: "contain",
      background: "white",
    },
  })

  const imgRef = useRef<HTMLImageElement>(null)

  const onUpdate = React.useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef
    if (img) {
      const value = make3dTransformValue({ x, y, scale })
      img.style.setProperty("transform", value)
    }
  }, [])

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" alignItems="center" position="relative">
        <Hidden smDown={true} implementation="css">
          <Box display="flex" flexDirection="column" p={1}>
            {images.map((image, index) => {
              const srcSet = [1, 2, 3, 4]
                .map((size) => {
                  const height = size * thumbHeight
                  const width = size * thumbWidth
                  const src = getImgSrc(imageBucket, image.src, {
                    resize: {
                      width,
                      height,
                      fit: "contain",
                      background: "white",
                    },
                  })
                  return `${src} ${width}w`
                })
                .join(", ")

              const lowSrc = getImgSrc(imageBucket, image.src, {
                resize: {
                  width: thumbHeight / 2,
                  height: thumbWidth / 2,
                  fit: "contain",
                  background: "white",
                },
              })

              const handleClick = () => {
                setCurrentIndex(index)
              }

              return (
                <ButtonBase
                  className={clsx(classes.thumb, index === currentIndex && classes.currentThumb)}
                  key={index}
                  onClick={handleClick}
                  focusRipple={true}
                >
                  <LazySizesImg
                    alt={image.alt}
                    data-sizes="auto"
                    data-srcset={srcSet}
                    data-lowsrc={lowSrc}
                    draggable={false}
                    title={image.title}
                  />
                </ButtonBase>
              )
            })}
          </Box>
        </Hidden>
        <SwipeableViews
          index={currentIndex}
          onChangeIndex={handleChangeIndex}
          disableLazyLoading={true} // Because the images already implement lazyloading.
          slideClassName={classes.slide}
        >
          {images.map((image, key) => {
            const srcSet = [1, 2, 3, 4, 5]
              .map((size) => {
                const height = size * imageHeight
                const width = size * imageWidth
                const src = getImgSrc(imageBucket, image.src, {
                  resize: {
                    width,
                    height,
                    fit: "contain",
                    background: "white",
                  },
                })
                return `${src} ${width}w`
              })
              .join(", ")

            const lowSrc = getImgSrc(imageBucket, image.src, {
              resize: {
                width: imageWidth / 2,
                height: imageHeight / 2,
                fit: "contain",
                background: "white",
              },
            })

            return (
              <ButtonBase
                className={classes.image}
                key={key}
                onClick={handleOpenDialog}
                disabled={key !== currentIndex}
                focusRipple={true}
              >
                <LazySizesImg
                  alt={image.alt}
                  data-sizes="auto"
                  data-srcset={srcSet}
                  data-lowsrc={lowSrc}
                  draggable={false}
                  title={image.title}
                />
              </ButtonBase>
            )
          })}
        </SwipeableViews>
        <Hidden mdUp={true} implementation="css">
          <IconButton
            aria-label="vorheriges Bild"
            className={clsx(classes.button, classes.prevButton)}
            disabled={isFirstSlide}
            onClick={handlePrevClick}
            size="small"
          >
            <ChevronLeftIcon fontSize="large" />
          </IconButton>
        </Hidden>
        <Hidden mdUp={true} implementation="css">
          <IconButton
            aria-label="nächstes Bild"
            className={clsx(classes.button, classes.nextButton)}
            disabled={isLastSlide}
            onClick={handleNextClick}
            size="small"
          >
            <ChevronRightIcon fontSize="large" />
          </IconButton>
        </Hidden>
      </Box>
      <Dialog open={dialogOpen} fullScreen={true} onClose={handleCloseDialog}>
        <DialogContent className={classes.dialogContent}>
          <QuickPinchZoom onUpdate={onUpdate}>
            <img ref={imgRef} src={zoomSrc} alt={currentImage.title} title={currentImage.title} />
          </QuickPinchZoom>
          <IconButton
            aria-label="schließen"
            className={classes.closeButton}
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        imageBucket
      }
    }
  }
`
