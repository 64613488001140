import { Box, Grid, Hidden, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { JsonLd } from "react-schemaorg"
import { Product as ProductSchema, WithContext } from "schema-dts"

import { AccordionPanels, AccordionPanelsProps } from "../components/AccordionPanels"
import { FavoritesButton } from "../components/FavoritesButton"
import { appBarHeight } from "../components/Header"
import { ImageSlider } from "../components/ImageSlider"
import { Layout } from "../components/Layout"
import { ShopButton } from "../components/ShopButton"
import { Favorite } from "../interfaces/favorite"
import { ProductPageContext } from "../interfaces/pageContext"
import { formatPrice } from "../utils/format"
import { getImgSrc } from "../utils/images"

const OG_IMAGE_WIDTH = 1200
const OG_IMAGE_HEIGHT = 628
const MAX_OG_SEE_ALSO = 6
const BREADCRUMBS_HEIGHT = 40

const useStyles = makeStyles((theme) => {
  /* NOTE: Height is 100vh - Header - Padding - Breadcrumbs */
  const columnHeight = {
    sm: `calc(100vh - ${appBarHeight.sm}px - ${theme.spacing(1)}px - ${BREADCRUMBS_HEIGHT}px)`,
    xs: `calc(100vh - ${appBarHeight.xs}px - ${theme.spacing(1)}px - ${BREADCRUMBS_HEIGHT}px)`,
  }
  return {
    brand: {
      fontSize: "1.5em",
      textTransform: "uppercase",
    },
    aboveTheFold: {
      height: columnHeight.xs,
      maxHeight: columnHeight.xs,
      [theme.breakpoints.up("sm")]: {
        overflow: "hidden",
        height: columnHeight.sm,
        maxHeight: columnHeight.sm,
      },
    },
    belowTheFold: {
      paddingTop: theme.spacing(2),
    },
    columnLeft: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
    columnRight: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
    deliveryCost: {
      color: theme.palette.grey[600],
      fontSize: "0.8rem",
    },
    description: {
      maxHeight: "15vh",
      overflowY: "scroll",
    },
    info: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      padding: `0 ${theme.spacing(1)}px`,
    },
    name: {
      fontSize: "1.25rem",
      lineHeight: 1.17,
      textAlign: "center",
      [theme.breakpoints.up("md")]: {
        textAlign: "left",
      },

      display: "-webkit-box",
      overflow: "hidden",
      lineClamp: 2,
      "-webkit-box-orient": "vertical",
      textOverflow: "ellipsis",
    },
    sale: {
      [theme.breakpoints.only("xs")]: {
        color: theme.palette.error.main,
      },
      fontSize: "larger",
    },
    discount: {
      color: theme.palette.error.main,
      fontSize: "small",
    },
    price: {
      fontSize: "1.25em",
      fontWeight: "normal",
    },
    priceBefore: {
      fontSize: "1.5em",
      fontWeight: "bold",
      textDecoration: "line-through",
    },
    similar: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    similarSlide: {
      height: "auto",
    },
  }
})

export type ProductPageProps = PageProps<any, ProductPageContext>

const ProductPage: React.FC<ProductPageProps> = (props) => {
  const classes = useStyles(props)

  const { data, location, pageContext } = props
  const { related, product } = pageContext
  const {
    awinProductId,
    brand,
    colors,
    currency,
    deepLink,
    deliveryCost,
    deliveryTime,
    description,
    discount,
    gtin,
    images,
    material,
    merchantId,
    merchantProductId,
    mpn,
    name,
    price,
    priceBefore,
    sale,
    slug,
  } = product.fields

  const { allProgrammeDetails, site } = data
  const { siteMetadata } = site
  const { imageBucket, siteUrl } = siteMetadata

  const programmeDetails = allProgrammeDetails.edges.find(({ node }: any) => node.programmeInfo.id === merchantId)
  const commissionRange = programmeDetails.node.commissionRange.find(({ type }: any) => type === "percentage")
  const avgCommissionPercentage = (commissionRange.min + commissionRange.max) / 2 / 100
  const commission = (price * avgCommissionPercentage).toFixed(2)

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        "Product ID": awinProductId,
        "Product Name": name,
      })
    }
  })

  const accordionProps: AccordionPanelsProps = { panels: [] }
  if (description) {
    accordionProps.panels.push({
      details: (
        <Typography
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          gutterBottom={false}
          variant="body2"
        />
      ),
      summary: <Typography component="h2">Beschreibung</Typography>,
    })
  }
  if (colors.length > 0 || material || deliveryTime) {
    accordionProps.panels.push({
      details: (
        <Box display="flex" flexDirection="column">
          {colors.length > 0 && <Typography>Farben: {colors.join(", ")}</Typography>}
          {material && <Typography>Material: {material}</Typography>}
          {deliveryTime && <Typography>Lieferzeit: {deliveryTime}</Typography>}
        </Box>
      ),
      summary: <Typography component="h2">Produktdetails</Typography>,
    })
  }

  const url = `${siteUrl}${slug}`

  const productSchema: WithContext<ProductSchema> = {
    "@context": "https://schema.org",
    "@type": "Product",
    brand,
    description,
    image: images.map((image) =>
      getImgSrc(imageBucket, image.src, {
        resize: { width: 1000, height: 1000, fit: "contain", background: "white" },
      })
    ),
    name,
    offers: {
      "@type": "Offer",
      availability: "https://schema.org/InStock",
      itemCondition: "https://schema.org/NewCondition",
      price,
      priceCurrency: currency,
      url,
    },
  }
  if (gtin) {
    productSchema.gtin13 = gtin
  }
  if (merchantProductId) {
    productSchema.sku = merchantProductId
  }
  if (mpn) {
    productSchema.mpn = mpn
  }

  const ogImage = getImgSrc(imageBucket, images[0].src, {
    resize: { width: OG_IMAGE_WIDTH, height: OG_IMAGE_HEIGHT, fit: "contain", background: "white" },
    toFormat: `jpeg`,
  })

  const metaDescription = `${name} online kaufen bei buybags.de`
  pageContext.title = name

  const favorite: Favorite = {
    image: images[0].src,
    name,
    slug,
  }

  return (
    <Layout location={location} pageContext={pageContext}>
      <Helmet defer={false}>
        <meta name="description" content={metaDescription} />
        <meta property="og:availability" content="instock" />
        <meta property="og:brand" content={brand} />
        <meta property="og:description" content={metaDescription} />
        {gtin && <meta property="og:ean" content={gtin} />}
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:alt" content={name} />
        <meta property="og:image:width" content={`${OG_IMAGE_WIDTH}`} />
        <meta property="og:image:height" content={`${OG_IMAGE_HEIGHT}`} />
        {related.slice(0, MAX_OG_SEE_ALSO).map((relatedProduct, index) => (
          <meta
            property="og:see_also"
            content={`${siteUrl}${relatedProduct.fields.slug}`}
            key={index}
          />
        ))}
        <meta property="og:title" content={name} />
        <meta property="og:type" content="product" />
        <meta property="product:price:amount" content={`${price}`} />
        <meta property="product:price:currency" content={currency} />
        {sale && <meta property="product:price:standard_amount" content={`${priceBefore}`} />}
      </Helmet>
      <JsonLd<ProductSchema> item={productSchema} />

      <Grid container={true} spacing={1} alignItems="stretch" className={classes.aboveTheFold}>
        <Grid item={true} xs={12} md={8} className={classes.columnLeft}>
          <ImageSlider images={images} />
        </Grid>

        <Grid item={true} xs={12} md={4} className={classes.columnRight}>
          <Typography variant="h1" className={classes.name}>
            {name}
          </Typography>

          <Grid container={true} spacing={2} justify="center" alignItems="center">
            {sale && (
              <Grid item={true}>
                <Typography component="span" className={classes.priceBefore}>
                  {formatPrice(priceBefore, currency)}
                </Typography>
              </Grid>
            )}
            <Grid item={true}>
              <Typography component="span" className={clsx(classes.price, sale && classes.sale)}>
                {formatPrice(price, currency)}
              </Typography>
            </Grid>
          </Grid>
          <Hidden only="xs" implementation="css">
            <Typography component="span" className={classes.deliveryCost}>
              <Box display="flex" flexDirection="column" alignItems="center">
                {sale && <span className={classes.discount}>{discount} reduziert!</span>}
                <span>
                  {deliveryCost > 0
                    ? `zzgl. ${formatPrice(deliveryCost, currency)} Versand`
                    : `kostenloser Versand`}
                </span>
              </Box>
            </Typography>
          </Hidden>

          <Grid container={true} spacing={1} direction="column">
            <Grid item={true}>
              <ShopButton href={deepLink} name={name} productId={awinProductId} commission={commission} currency={currency} />
            </Grid>
            <Grid item={true}>
              <FavoritesButton favorite={favorite} />
            </Grid>
          </Grid>

          <Hidden smDown={true} implementation="css">
            <AccordionPanels id="accordion-panels-mdup" {...accordionProps} />
          </Hidden>
        </Grid>
      </Grid>

      <Grid container={true} spacing={1} direction="column" className={classes.belowTheFold}>
        <Hidden mdUp={true} implementation="css">
          <Grid item={true}>
            <AccordionPanels id="accordion-panels-smdown" {...accordionProps} />
          </Grid>
        </Hidden>
      </Grid>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query ProductPageQuery {
    allProgrammeDetails {
      edges {
        node {
          commissionRange {
            min
            max
            type
          }
          programmeInfo {
            id
          }
        }
      }
    }
    site {
      siteMetadata {
        imageBucket
        siteUrl
      }
    }
  }
`
