import { Hidden, makeStyles } from "@material-ui/core"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import { Button } from "gatsby-theme-material-ui"
import React, { ReactElement } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Favorite } from "../interfaces/favorite"
import { RootState } from "../redux/ducks"
import { openFavoritesDrawer } from "../redux/ducks/drawer"
import { addFavorite } from "../redux/ducks/favorites"

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    borderRadius: 0,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}))

export interface FavoritesButtonProps {
  favorite: Favorite
  size?: "small" | "medium" | "large"
}

export function FavoritesButton(props: FavoritesButtonProps): ReactElement {
  const { favorite, size = "large" } = props
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const favorites = useSelector((state: RootState) => state.favorites.favorites)

  const handleFavoritesClick = (): void => {
    dispatch(addFavorite(favorite))
    dispatch(openFavoritesDrawer())
  }

  const isFavorite = !!favorites.find((f) => f.slug === favorite.slug)

  return (
    <Hidden xsUp={isFavorite} implementation="css">
      <Button
        variant="outlined"
        color="primary"
        fullWidth={true}
        classes={{
          root: classes.buttonRoot,
        }}
        size={size}
        onClick={handleFavoritesClick}
      >
        Auf die Wunschliste
        <FavoriteBorderIcon className={classes.icon} />
      </Button>
    </Hidden>
  )
}
